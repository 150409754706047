import * as React from 'react';
import { Container, Col, Form, FormGroup, Jumbotron, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ContactFormModel, Photo } from '../../../functions/src/contact-form-model';
import { LanguageContext, LanguageState, LocaleLanguage } from '../../contexts/language-context';
import { AppRoutes } from '../../models/routes';
import { translations } from '../../resources/translations';
import ImageUploader from 'react-images-upload';

export const ContactScreen: React.FC = () => {
	const history = useHistory();

	const [fullName, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [licenseNumber, setLicense] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [photos, setPhotos] = React.useState<Photo[]>(null);

	// const from = 'evan.s.richards@gmail.com';
	// const toEmail = 'evan.s.richards@gmail.com';
	const from = 'gspr@gspr.is';
	const toEmail = 'gspr@gspr.is';
	const subject = `Message from ${fullName}`;
	const fullMessage = `Name: ${fullName}\nEmail: ${email}\nPhone: ${phone}\nLicense: ${licenseNumber}\nMessage: ${message}\n`;

	const handleChange = (files: File[], pictures: string[]) => {
		setPhotos(null);
		if (files.length && files.length <= 3) {
			const newPhotos = [];
			files.forEach((file, i) => {
				const p: Photo = {
					data: pictures[i],
					fileName: file.name,
					photoType: file.type,
				};
				newPhotos.push(p);
			});
			setPhotos(newPhotos);
		} else if (files.length > 3) {
			alert('Too many photos selected');
		}
	};

	// eslint-disable-next-line
	const validateEmail = (email: string) =>
		/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/.test(email) ? true : false;

	const sendMessage = async (event: any, language: LocaleLanguage) => {
		event.preventDefault();
		try {
			setLoading(true);
			let firebaseFunctionUrl: string = 'https://us-central1-body-shop-project.cloudfunctions.net/sendemail';
			let contactForm: ContactFormModel = {
				fromName: fullName,
				fromEmail: from,
				replyTo: email,
				toEmail,
				subject,
				message: fullMessage,
				photos,
			};
			console.log(JSON.stringify(contactForm));
			axios
				.post(firebaseFunctionUrl, contactForm)
				.then((res: any) => {
					console.log(res);
					setLoading(false);
					alert(
						language === LocaleLanguage.EN
							? 'Your message has been sent!'
							: 'Skilaboðin þín hafa verið send',
					);
					setTimeout(() => history.push(AppRoutes.HOME), 1000);
				})
				.catch((e: any) => {
					setLoading(false);
					alert(e);
				});
		} catch (e) {
			alert(e);
			setLoading(false);
		}
	};

	const [isLoading, setLoading] = React.useState(false);

	const isValid = fullName.length > 0 && validateEmail(email) && message.length > 0 && photos?.length;

	return (
		<LanguageContext.Consumer>
			{({ selectedLanguage, setLanguage }: LanguageState) => (
				<>
					<Jumbotron fluid className="contact-cover"></Jumbotron>
					<Container className="contact">
						<div className="content">
							<h1 style={{ marginBottom: '1rem' }}>{translations.contact.title[selectedLanguage]}</h1>
							<h3 style={{ marginBottom: '2rem' }}>{translations.contact.subtitle[selectedLanguage]}</h3>
						</div>
						<Row className="justify-content-md-center">
							<Col lg="4">
								<Form className="content">
									<FormGroup>
										<Form.Label>{translations.contact.name[selectedLanguage]}</Form.Label>
										<Form.Control
											name="fullName"
											value={fullName}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setName(e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Form.Label>{translations.contact.email[selectedLanguage]}</Form.Label>
										<Form.Control
											name="emailAddress"
											type="email"
											value={email}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setEmail(e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Form.Label>{translations.contact.phone[selectedLanguage]}</Form.Label>
										<Form.Control
											name="phoneNumber"
											type="tel"
											value={phone}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setPhone(e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Form.Label>{translations.contact.license[selectedLanguage]}</Form.Label>
										<Form.Control
											name="licenseNumber"
											value={licenseNumber}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												setLicense(e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Form.Label>{translations.contact.message[selectedLanguage]}</Form.Label>
										<Form.Control
											as="textarea"
											rows={5}
											style={{ resize: 'none' }}
											value={message}
											onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
												setMessage(e.target.value)
											}
										/>
									</FormGroup>
									<FormGroup>
										<ImageUploader
											singleImage={false}
											withIcon={true}
											buttonText={
												selectedLanguage === LocaleLanguage.EN
													? 'Take or choose image (3 max)'
													: 'Taka eða velja mynd (3 hámark)'
											}
											onChange={handleChange}
											withPreview={true}
											imgExtension={['.jpg', '.png']}
											maxFileSize={10242880}
											label={
												selectedLanguage === LocaleLanguage.EN
													? 'Max Size: 10MB. Supported: PNG, JPEG'
													: 'Hámark: 10MB. PNG, JPEG'
											}
											fileSizeError={
												selectedLanguage === LocaleLanguage.EN
													? 'File size is too large'
													: 'Þessi skrá er of stór'
											}
											fileTypeError={
												selectedLanguage === LocaleLanguage.EN
													? 'File type error'
													: 'Tegund skráar ekki studd'
											}
										/>
									</FormGroup>
									<div className="form-group">
										<button
											className="primary"
											disabled={!isValid}
											onClick={(e) => sendMessage(e, selectedLanguage)}
										>
											{isLoading
												? translations.contact.sending[selectedLanguage]
												: translations.contact.send[selectedLanguage]}
										</button>
									</div>
								</Form>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</LanguageContext.Consumer>
	);
};
