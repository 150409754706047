import React from 'react';
import * as ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { HomeScreen } from '../Home';
import { ContactScreen } from '../Contact';
import { AppRoutes as RouteType } from '../../models/routes';
import withTracker from '../with-tracker';
import { NavigationBar } from '../../components/navigation-bar';
import { Footer } from '../../components/footer';
import { LanguageContext, LanguageState, LocaleLanguage } from '../../contexts/language-context';
import { PaintlessScreen } from '../Paintless';
import { TraditionalScreen } from '../Traditional';
import { DamageRepairScreen } from '../Repair';
import ScrollToTop from './ScrollToTop';
import { MapScreen } from '../Map';

function initializeReactGA() {
	/* TODO: Change tracking code */
	// ReactGA.initialize('UA-136098631-1', { debug: true });
	ReactGA.pageview('/');
}

export class App extends React.Component<any, LanguageState> {
	constructor(props) {
		super(props);
		this.setLanguage = (lang: LocaleLanguage) => {
			this.setState({
				selectedLanguage: lang,
			});
		};
		this.state = {
			selectedLanguage: LocaleLanguage.IS,
			setLanguage: this.setLanguage,
		};
	}

	public setLanguage = (lang: LocaleLanguage) => {
		this.setState({ selectedLanguage: lang });
	};

	public render() {
		initializeReactGA();
		return (
			<Router>
				<ScrollToTop />
				<LanguageContext.Provider value={this.state}>
					<div>
						<NavigationBar />
						<div className="app-container">
							<Switch>
								<Route path={RouteType.CONTACT} component={withTracker(ContactScreen)} />
								<Route path={RouteType.MAP} component={withTracker(MapScreen)} />
								<Route path={RouteType.PAINTLESS} component={withTracker(PaintlessScreen)} />
								<Route path={RouteType.TRADITIONAL} component={withTracker(TraditionalScreen)} />
								<Route path={RouteType.DAMAGE_REPAIR} component={withTracker(DamageRepairScreen)} />
								<Route path={RouteType.BASE} component={withTracker(HomeScreen)} />
							</Switch>
						</div>
						<Footer />
					</div>
				</LanguageContext.Provider>
			</Router>
		);
	}
}
