import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import { LanguageContext, LanguageState } from '../../contexts/language-context';
import { translations } from '../../resources/translations';
import { LinkContainer } from 'react-router-bootstrap';
import { AppRoutes } from '../../models/routes';
import tr1 from '../../resources/images/traditional/tr1.jpg';
import tr2 from '../../resources/images/traditional/tr2.jpg';
import tr3 from '../../resources/images/traditional/tr3.jpg';
import tr4a from '../../resources/images/traditional/tr4-a.jpg';
import tr4b from '../../resources/images/traditional/tr4-b.jpg';

export const TraditionalScreen: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<>
				<Jumbotron fluid className="traditional-cover"></Jumbotron>
				<Container className="service">
					<div className="content">
						<h1 style={{ marginBottom: '1rem' }}>
							{translations.services.traditional.title[selectedLanguage]}
						</h1>
						<h3 style={{ marginBottom: '2rem' }}>
							{translations.services.traditional.description[selectedLanguage]}
						</h3>
						<LinkContainer to={AppRoutes.CONTACT}>
							<button className="primary">{translations.home.jumbotron.ctaBtn[selectedLanguage]}</button>
						</LinkContainer>
						<br />
						<br />
						<h1 style={{ marginBottom: '1rem' }}>{translations.services.ourWork[selectedLanguage]}</h1>
						<br />
					</div>
					<div className="tr-images">
						<img src={tr1} alt="Traditional dent repair 1" />
					</div>
					<div className="tr-images">
						<img src={tr2} alt="Traditional dent repair 2" />
					</div>
					<div className="tr-images">
						<img src={tr3} alt="Traditional dent repair 3" />
					</div>
					<div className="tr-images">
						<img src={tr4a} alt="Traditional dent repair 4" />
					</div>
					<div className="tr-images">
						<img src={tr4b} alt="Traditional dent repair 5" />
					</div>
				</Container>
			</>
		)}
	</LanguageContext.Consumer>
);
