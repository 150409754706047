import React from 'react';
import { Nav, Navbar as BootstrapNav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Flag from 'react-flags';
import { AppRoutes } from '../models/routes';
import { LanguageContext, LanguageState, LocaleLanguage, SelectableCode } from '../contexts/language-context';
import logo from '../resources/images/logo.png';

export const NavigationBar: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<BootstrapNav collapseOnSelect expand="lg" variant="light">
				<LinkContainer to={AppRoutes.HOME}>
					<img src={logo} alt="Logo" />
				</LinkContainer>
				<Nav className="mr-auto"></Nav>
				<Nav>
					<Nav.Link
						onClick={() =>
							setLanguage(selectedLanguage === LocaleLanguage.EN ? LocaleLanguage.IS : LocaleLanguage.EN)
						}
					>
						<Flag
							name={selectedLanguage === LocaleLanguage.EN ? SelectableCode['en-us'] : SelectableCode.is}
							format="png"
							pngSize={32}
							shiny={true}
							alt="Country Flag"
							basePath="img/flags"
						/>
					</Nav.Link>
				</Nav>
			</BootstrapNav>
		)}
	</LanguageContext.Consumer>
);
