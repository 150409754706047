import * as React from 'react';

export const SelectableCode = {
	'en-us': 'IS',
	is: 'GB',
};

export enum LocaleLanguage {
	EN = 'en-us',
	IS = 'is',
}

export interface LanguageState {
	selectedLanguage: LocaleLanguage;
	setLanguage: (lang: LocaleLanguage) => any;
}

export const LanguageContext = React.createContext({
	selectedLanguage: LocaleLanguage.EN,
	setLanguage: (lang: LocaleLanguage) => {},
} as LanguageState);
