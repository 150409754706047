export enum AppRoutes {
	BASE = '/',
	CONTACT = '/contact',
	GALLERY = '/gallery',
	HOME = '/home',
	TRADITIONAL = '/bilasprautun',
	PAINTLESS = '/smarettingar',
	DAMAGE_REPAIR = '/thjonaskodun',
	MAP = '/map',
}
