import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { LanguageContext, LanguageState } from '../../contexts/language-context';
import { translations } from '../../resources/translations';
import { LinkContainer } from 'react-router-bootstrap';
import { AppRoutes } from '../../models/routes';
// import pdr1 from '../../resources/images/pdr/pdr-before.jpg';
// import pdr2 from '../../resources/images/pdr/pdr-after.jpg';

export const DamageRepairScreen: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<>
				<Helmet>
					<title>Smáréttingar | Gæðasprautun</title>
				</Helmet>
				<Jumbotron fluid className="repair-cover"></Jumbotron>
				<Container className="service">
					<div className="content">
						<h1 style={{ marginBottom: '2rem' }}>{translations.services.repair.title[selectedLanguage]}</h1>
						<h3 style={{ marginBottom: '1rem' }}>
							{translations.services.repair.description1[selectedLanguage]}
						</h3>
						<LinkContainer to={AppRoutes.CONTACT}>
							<button className="primary">{translations.home.jumbotron.ctaBtn[selectedLanguage]}</button>
						</LinkContainer>
						<br />
						{/* <h1 style={{ marginBottom: '1rem' }}>{translations.services.ourWork[selectedLanguage]}</h1>
						<br /> */}
					</div>
					{/* <div className="images">
						<img src={pdr1} alt="Paintless dent repair before" />
						<img src={pdr2} alt="Paintless dent repair after" />
					</div> */}
				</Container>
			</>
		)}
	</LanguageContext.Consumer>
);
