import { LocaleLanguage } from '../contexts/language-context';

export const translations = {
	contact: {
		title: {
			[LocaleLanguage.EN]: 'Contact Us',
			[LocaleLanguage.IS]: 'Sendu okkur skilaboð',
		},
		subtitle: {
			[LocaleLanguage.EN]: `Send us a message and we'll reply as soon as we can.`,
			[LocaleLanguage.IS]: `Sendu okkur skilaboð og við svörum eins fljótt og við getum.`,
		},
		name: {
			[LocaleLanguage.EN]: 'Name',
			[LocaleLanguage.IS]: 'Nafn',
		},
		address: {
			[LocaleLanguage.EN]: 'Address',
			[LocaleLanguage.IS]: 'Heimilisfang',
		},
		email: {
			[LocaleLanguage.EN]: 'Email',
			[LocaleLanguage.IS]: 'Netfang',
		},
		phone: {
			[LocaleLanguage.EN]: 'Phone Number',
			[LocaleLanguage.IS]: 'Símanúmer',
		},
		license: {
			[LocaleLanguage.EN]: 'License Number',
			[LocaleLanguage.IS]: 'Númeraplata',
		},
		message: {
			[LocaleLanguage.EN]: 'Message',
			[LocaleLanguage.IS]: 'Skilaboð',
		},
		send: {
			[LocaleLanguage.EN]: 'Send',
			[LocaleLanguage.IS]: 'Senda',
		},
		sending: {
			[LocaleLanguage.EN]: 'Sending...',
			[LocaleLanguage.IS]: 'að senda...',
		},
		sentMessage: {
			[LocaleLanguage.EN]: 'Your message has been sent!',
			[LocaleLanguage.IS]: 'Skilaboðin þín hafa verið send!',
		},
	},
	home: {
		jumbotron: {
			title: {
				[LocaleLanguage.EN]: 'Do you have a dent or scratch on your car?',
				[LocaleLanguage.IS]: 'Er kominn beygla eða rispa á bílinn?',
			},
			subtitle: {
				[LocaleLanguage.EN]: 'Let us fix it for you. Receive a price estimate today.',
				[LocaleLanguage.IS]: `Gæðasprautun & gæðaréttingar er réttingaverkstæði staðsett í Súðarvogi 38. Við leysum málið hratt og örugglega fyrir þig.`,
			},
			ctaBtn: {
				[LocaleLanguage.EN]: 'Request estimate',
				[LocaleLanguage.IS]: 'Fá tilboð',
			},
		},
		featureBtn: {
			[LocaleLanguage.EN]: 'More',
			[LocaleLanguage.IS]: 'Nánar',
		},
		sectionTitles: {
			testimonials: {
				title: {
					[LocaleLanguage.EN]: 'Check out our Facebook page for reviews from our customers!',
					[LocaleLanguage.IS]: 'Skoðaðu Facebook síðu okkar fyrir umsagnir frá viðskiptavinum okkar!',
				},
				button: {
					[LocaleLanguage.EN]: 'View on Facebook',
					[LocaleLanguage.IS]: 'Skoða á Facebook',
				},
			},
			ourServices: {
				[LocaleLanguage.EN]: 'Our Services',
				[LocaleLanguage.IS]: 'Þjónusta okkar',
			},
			partners: {
				[LocaleLanguage.EN]: 'Our insurance partners',
				[LocaleLanguage.IS]: 'Tryggingafélagar okkar',
			},
		},
		features: {
			service: {
				title: {
					[LocaleLanguage.EN]: 'Service',
					[LocaleLanguage.IS]: 'Þjónusta',
				},
				text: {
					[LocaleLanguage.EN]: 'Customers trust our service and they know our professionals do quality work.',
					[LocaleLanguage.IS]:
						'Viðskiptavinir treysta þjónustu okkar og þeir vita að fagfólk okkar vinnur vandaða vinnu.',
				},
			},
			insurance: {
				title: {
					[LocaleLanguage.EN]: 'Insurance',
					[LocaleLanguage.IS]: 'Tryggingar',
				},
				text: {
					[LocaleLanguage.EN]: 'We accept and work well with all insurance companies.',
					[LocaleLanguage.IS]: 'Við þjónustum öll tryggingarfélögin og erum í góðu samstarfi með þeim.',
				},
			},
			quality: {
				title: {
					[LocaleLanguage.EN]: 'Quality',
					[LocaleLanguage.IS]: 'Gæði',
				},
				text: {
					[LocaleLanguage.EN]: 'Customers trust our service and they know our professionals do quality work.',
					[LocaleLanguage.IS]:
						'Viðskiptavinir treysta þjónustu okkar og þeir vita að fagfólk okkar vinnur vandaða vinnu.',
				},
			},
			damageInspection: {
				title: {
					[LocaleLanguage.EN]: 'Damage Inspection',
					[LocaleLanguage.IS]: 'Tjónaskoðun',
				},
				text: {
					[LocaleLanguage.EN]: 'Bring the car to us and let us assess the damage.',
					[LocaleLanguage.IS]: 'Komdu með bifreiðina til okkar og láttu okkur meta tjónið.',
				},
			},
			technology: {
				title: {
					[LocaleLanguage.EN]: 'Technology',
					[LocaleLanguage.IS]: 'Tækni',
				},
				text: {
					[LocaleLanguage.EN]: 'We have good facilities and use modern technology in car repairs.',
					[LocaleLanguage.IS]:
						'Við erum með góða aðstöðu og notumst við nútíma tækni í viðgerðum á bifreiðum.',
				},
			},
		},
		services: {
			paintless: {
				title: {
					[LocaleLanguage.EN]: 'Paintless Dent Repair (PDR)',
					[LocaleLanguage.IS]: 'Smáréttingar',
				},
				features: [
					{
						[LocaleLanguage.EN]: 'Original paint and finish kept intact',
						[LocaleLanguage.IS]: 'Upprunalegri áferð viðhaldið',
					},
					{
						[LocaleLanguage.EN]: 'Fast',
						[LocaleLanguage.IS]: 'Fljótleg',
					},
					{
						[LocaleLanguage.EN]: 'Economical',
						[LocaleLanguage.IS]: 'Hagkvæm',
					},
					{
						[LocaleLanguage.EN]: 'Environmentally friendly',
						[LocaleLanguage.IS]: 'Umhverfisvæn',
					},
				],
			},
			repair: {
				title: {
					[LocaleLanguage.EN]: 'Damage Repair',
					[LocaleLanguage.IS]: 'Tjónaskoðun',
				},
				features: [
					{
						[LocaleLanguage.EN]: 'Damage assessment',
						[LocaleLanguage.IS]: 'Metum tjónið',
					},
					{
						[LocaleLanguage.EN]: 'Accept all insurance companies',
						[LocaleLanguage.IS]: 'Vinnum fyrir tryggingarfélögin',
					},
					{
						[LocaleLanguage.EN]: 'Personal service',
						[LocaleLanguage.IS]: 'Persónuleg þjónusta',
					},
				],
			},
			traditional: {
				title: {
					[LocaleLanguage.EN]: 'Auto paint & collision repair',
					[LocaleLanguage.IS]: 'Bílasprautun og réttingar',
				},
				features: [
					{
						[LocaleLanguage.EN]: 'Fixes deep scratches and dents',
						[LocaleLanguage.IS]: 'Fyrir dýpri rispur og stærri tjón',
					},
					{
						[LocaleLanguage.EN]: 'Revives faded paint',
						[LocaleLanguage.IS]: 'Endurlífgar dofna málningu',
					},
					{
						[LocaleLanguage.EN]: 'Rust prevention',
						[LocaleLanguage.IS]: 'Fyrirbyggir ryð',
					},
					{
						[LocaleLanguage.EN]: 'New look',
						[LocaleLanguage.IS]: 'Gefur nýja ásýnd',
					},
				],
			},
		},
	},
	services: {
		repair: {
			title: {
				[LocaleLanguage.EN]: 'Damage Repair',
				[LocaleLanguage.IS]: 'Tjónaskoðun',
			},
			description1: {
				[LocaleLanguage.EN]: `You will get a review and repair cost estimate during the damage inspection. We accept all insurance companies: Sjóvá, TM, Vís and Vörð. Contact us for more information.`,
				[LocaleLanguage.IS]: `Við tjónaskoðun bifreiðina og þú færð umsögn og kostnaðarmat á viðgerðinni. Við vinnum fyrir öll tryggingarfélögin þ.e.a.s. Sjóvá, TM, Vís og Vörð. Hafðu samband fyrir frekari upplýsingar.`,
			},
		},
		paintless: {
			title: {
				[LocaleLanguage.EN]: 'Paintless Dent Repair (PDR)',
				[LocaleLanguage.IS]: 'Smáréttingar',
			},
			description1: {
				[LocaleLanguage.EN]: `Minor straightening is a modern technology for repairing car dents that can occur after it has been fitted e.g. to door your car. Minor repairs allow us to repair the car quickly, efficiently and environmentally friendly.`,
				[LocaleLanguage.IS]: `Smáréttingar er nútíma tækni til að gera við beyglur á bílum sem geta komið eftir að það sé búið t.d. að hurða bílinn þinn. Smáréttingar gerir okkur kleift að gera við bílinn á fljótlegan, hagkvæman og umhverfisvænan hátt. `,
			},
			description2: {
				[LocaleLanguage.EN]: `Everyone hates seeing a dent in their car, whether small or large. PDR utilities new technologies and tools combined with expert skill to repair small dents that can occur by various means.`,
				[LocaleLanguage.IS]: `Við leggjum mikla áherslu á bjóða upp á góða og persónulega þjónustu fyrir okkar viðskiptavini. Því leggjum við okkur fram að klára viðgerðina samdægurs og þú verður ekki án bíls í marga daga.`,
			},
			description3: {
				[LocaleLanguage.EN]: `Contact us by phone and we will resolve issues.`,
				[LocaleLanguage.IS]: `Hafðu samband við okkur í gegnum síma og við leysum málin.`,
			},
		},
		traditional: {
			title: {
				[LocaleLanguage.EN]: 'Auto paint & collision repair',
				[LocaleLanguage.IS]: 'Bílasprautun og réttingar',
			},
			description: {
				[LocaleLanguage.EN]: `Our body shop utilizes high quality equipment for spraying and collision repair. To guarantee customer satisfaction, our professionals harness the best working methods to ensure the best possible quality outcome. Our shop is a CABAS certified collision repair shop that supports all insurance companies in the country.`,
				[LocaleLanguage.IS]: `Aðstaðan okkar er búin fullkomnum tækjabúnaði til bílamálunar og bílaréttinga. Mikið er lagt upp úr góðum vinnubrögðum til að tryggja bestu fáanlegu gæði og ánægju viðskiptavina. Verkstæðið er CABAS vottað réttingaverkstæði sem vinnur fyrir öll tryggingafélög landsins.`,
			},
		},
		ourWork: {
			[LocaleLanguage.EN]: 'Our Work',
			[LocaleLanguage.IS]: 'Starf Okkar',
		},
	},
	menu: {
		contact: {
			[LocaleLanguage.EN]: 'Request Estimate',
			[LocaleLanguage.IS]: 'Hafðu samband',
		},
	},
};
