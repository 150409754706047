import * as React from 'react';
import { Card, Container, Jumbotron, CardColumns, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { LanguageContext, LanguageState } from '../../contexts/language-context';
import { translations } from '../../resources/translations';
import { AppRoutes } from '../../models/routes';
import trust from '../../resources/images/icons/trust.png';
import professional from '../../resources/images/icons/professional.png';
import services from '../../resources/images/icons/services.png';
import sjovalogorgb from '../../resources/images/partners/sjovalogorgb.png';
import tm from '../../resources/images/partners/tm.png';
import vis from '../../resources/images/partners/vis.png';
import vordur from '../../resources/images/partners/vordur.jpg';

export const HomeScreen: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<>
				<Jumbotron fluid className="home">
					<Container style={{ maxWidth: 800 }}>
						<h1 style={{ marginBottom: '1rem', maxWidth: 500 }}>
							{translations.home.jumbotron.title[selectedLanguage]}
						</h1>
						<h3 style={{ marginBottom: '2rem' }}>
							{translations.home.jumbotron.subtitle[selectedLanguage]}
						</h3>
						<LinkContainer to={AppRoutes.CONTACT}>
							<button className="primary">{translations.home.jumbotron.ctaBtn[selectedLanguage]}</button>
						</LinkContainer>
					</Container>
				</Jumbotron>
				<section className="withImage">
					<CardColumns>
						<Card className="withImage">
							<Card.Img variant="top" src={trust} />
							<Card.Body>
								<Card.Title>{translations.home.features.insurance.title[selectedLanguage]}</Card.Title>
								<Card.Text>{translations.home.features.insurance.text[selectedLanguage]}</Card.Text>
							</Card.Body>
						</Card>
						<Card className="withImage">
							<Card.Img variant="top" src={professional} />
							<Card.Body>
								<Card.Title>
									{translations.home.features.damageInspection.title[selectedLanguage]}
								</Card.Title>
								<Card.Text>
									{translations.home.features.damageInspection.text[selectedLanguage]}
								</Card.Text>
							</Card.Body>
						</Card>
						<Card className="withImage">
							<Card.Img variant="top" src={services} />
							<Card.Body>
								<Card.Title>{translations.home.features.technology.title[selectedLanguage]}</Card.Title>
								<Card.Text>{translations.home.features.technology.text[selectedLanguage]}</Card.Text>
							</Card.Body>
						</Card>
					</CardColumns>
				</section>
				<section className="product withImage">
					<h2>{translations.home.sectionTitles.ourServices[selectedLanguage]}</h2>
					<br />
					<div className="product paintless">
						<div>
							<h2>{translations.home.services.paintless.title[selectedLanguage]}</h2>
							<ul>
								{translations.home.services.paintless.features.map((feature, i) => (
									<li key={i}>{feature[selectedLanguage]}</li>
								))}
							</ul>
							<LinkContainer to={AppRoutes.PAINTLESS}>
								<button className="secondary">{translations.home.featureBtn[selectedLanguage]}</button>
							</LinkContainer>
						</div>
					</div>
					<div className="product traditional">
						<div>
							<h2>{translations.home.services.traditional.title[selectedLanguage]}</h2>
							<ul>
								{translations.home.services.traditional.features.map((feature, i) => (
									<li key={i}>{feature[selectedLanguage]}</li>
								))}
							</ul>
							<LinkContainer to={AppRoutes.TRADITIONAL}>
								<button className="secondary">{translations.home.featureBtn[selectedLanguage]}</button>
							</LinkContainer>
						</div>
					</div>
					<div className="product damage">
						<div>
							<h2>{translations.home.services.repair.title[selectedLanguage]}</h2>
							<ul>
								{translations.home.services.repair.features.map((feature, i) => (
									<li key={i}>{feature[selectedLanguage]}</li>
								))}
							</ul>
							<LinkContainer to={AppRoutes.DAMAGE_REPAIR}>
								<button className="secondary">{translations.home.featureBtn[selectedLanguage]}</button>
							</LinkContainer>
						</div>
					</div>
				</section>
				<section className="testimonials withImage">
					<h2>{translations.home.sectionTitles.testimonials.title[selectedLanguage]}</h2>
					<button
						className="secondary"
						onClick={() => (window.location.href = 'https://www.facebook.com/Gaedasprautun/')}
					>
						{translations.home.sectionTitles.testimonials.button[selectedLanguage]}
					</button>
				</section>
				<section
					style={{
						margin: '0 auto',
						marginBottom: 30,
						alignContent: 'center',
					}}
				>
					<CardColumns
						style={{ padding: '0 auto', paddingBottom: 40, justifyContent: 'center', display: 'flex' }}
					>
						<Card style={{ flexBasis: 400 }}>
							{/* <Card.Img variant="top" src={trust} /> */}
							<Card.Body>
								<Card.Title>{translations.home.features.service.title[selectedLanguage]}</Card.Title>
								<Card.Text>{translations.home.features.service.text[selectedLanguage]}</Card.Text>
							</Card.Body>
						</Card>
						<Card style={{ flexBasis: 400 }}>
							{/* <Card.Img variant="top" src={professional} /> */}
							<Card.Body>
								<Card.Title>{translations.home.features.quality.title[selectedLanguage]}</Card.Title>
								<Card.Text>{translations.home.features.quality.text[selectedLanguage]}</Card.Text>
							</Card.Body>
						</Card>
					</CardColumns>
				</section>
				<section className="partners">
					<h2>{translations.home.sectionTitles.partners[selectedLanguage]}</h2>
					<Row>
						<a target="blank" href="https://www.sjova.is/">
							<img src={sjovalogorgb} alt="sjovalogorgb" />
						</a>
						<a target="blank" href="https://www.tm.is/tjon/okutaekid/">
							<img src={tm} alt="bilatryggingar" />
						</a>
						<a target="blank" href="https://vis.is/tjonstilkynningar/tilkynna">
							<img src={vis} alt="vis" />
						</a>
						<a target="blank" href="https://vordur.is/tjon">
							<img src={vordur} alt="vordur" />
						</a>
					</Row>
				</section>
			</>
		)}
	</LanguageContext.Consumer>
);
