import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import logo from '../resources/images/logo-stacked-white.svg';
import fb from '../resources/images/icons/facebook.png';
import ig from '../resources/images/icons/instagram.png';
import yt from '../resources/images/icons/youtube.png';
import { AppRoutes } from '../models/routes';

export const Footer: React.FC = () => {
	const history = useHistory();
	return (
		<footer className="footer">
			<div>
				<img src={logo} alt="Logo" />
			</div>
			<Row>
				<Col>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						href="#"
						onClick={() => {
							history.push(AppRoutes.MAP);
						}}
					>
						<p>
							Gæðasprautun ehf.
							<br />
							Súðarvogur 38
							<br />
							104 Reykjavík
						</p>
					</a>
				</Col>
				<Col>
					<p>
						Mán-Fös: 8-18
						<br />
						Laugardagur: 9-14
						<br />
						Sunudagur: (LOKAÐ)
					</p>
				</Col>
				<Col>
					<p>Sími: 557-1515</p>
					<p>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							href="#"
							onClick={() => {
								history.push(AppRoutes.CONTACT);
							}}
						>
							Netfang: gspr@gspr.is
						</a>
					</p>
					<div className="social-media">
						<img
							src={fb}
							alt="facebook"
							onClick={() => (window.location.href = 'https://www.facebook.com/Gaedasprautun/')}
						/>
						<img
							src={ig}
							alt="instagram"
							onClick={() =>
								(window.location.href = 'https://instagram.com/gaedasprautun3?igshid=qe4qnlqwcu1f')
							}
						/>
						<img src={yt} alt="youtube" />
					</div>
				</Col>
			</Row>
		</footer>
	);
};
