import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import { LanguageContext, LanguageState } from '../../contexts/language-context';
import { translations } from '../../resources/translations';
import pdr1 from '../../resources/images/pdr/pdr1-a.jpg';
import pdr2 from '../../resources/images/pdr/pdr1-b.jpg';
import pdr3 from '../../resources/images/pdr/pdr2-a.jpg';
import pdr4 from '../../resources/images/pdr/pdr2-b.jpg';
import pdr5 from '../../resources/images/pdr/pdr3-a.jpg';
import pdr6 from '../../resources/images/pdr/pdr3-b.jpg';
import pdr7 from '../../resources/images/pdr/pdr4.jpeg';
import { AppRoutes } from '../../models/routes';

export const PaintlessScreen: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<>
				<Helmet>
					<title>Smáréttingar | Gæðasprautun</title>
				</Helmet>
				<Jumbotron fluid className="paintless-cover"></Jumbotron>
				<Container className="service">
					<div className="content">
						<h1 style={{ marginBottom: '2rem' }}>
							{translations.services.paintless.title[selectedLanguage]}
						</h1>
						<h3 style={{ marginBottom: '1rem' }}>
							{translations.services.paintless.description1[selectedLanguage]}
						</h3>
						<h3 style={{ marginBottom: '1rem' }}>
							{translations.services.paintless.description2[selectedLanguage]}
						</h3>
						<h3 style={{ marginBottom: '2rem' }}>
							{translations.services.paintless.description3[selectedLanguage]}
						</h3>
						<LinkContainer to={AppRoutes.CONTACT}>
							<button className="primary">{translations.home.jumbotron.ctaBtn[selectedLanguage]}</button>
						</LinkContainer>
						<br />
						<br />
						<h1 style={{ marginBottom: '1rem' }}>{translations.services.ourWork[selectedLanguage]}</h1>
						<br />
					</div>
					<div className="images">
						<img src={pdr1} alt="Paintless dent repair before" />
						<img src={pdr2} alt="Paintless dent repair after" />
					</div>
					<div className="images">
						<img src={pdr3} alt="Paintless dent repair before" />
						<img src={pdr4} alt="Paintless dent repair after" />
					</div>
					<div className="images">
						<img src={pdr5} alt="Paintless dent repair before" />
						<img src={pdr6} alt="Paintless dent repair after" />
					</div>
					<div className="images">
						<img src={pdr7} alt="Paintless dent repair before" />
					</div>
				</Container>
			</>
		)}
	</LanguageContext.Consumer>
);
