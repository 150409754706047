import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Jumbotron } from 'react-bootstrap';
import { LanguageContext, LanguageState } from '../../contexts/language-context';
import { translations } from '../../resources/translations';

export const MapScreen: React.FC = () => (
	<LanguageContext.Consumer>
		{({ selectedLanguage, setLanguage }: LanguageState) => (
			<>
				<Helmet>
					<title>Smáréttingar | Gæðasprautun</title>
				</Helmet>
				<Jumbotron fluid className="contact-cover"></Jumbotron>
				<Container className="contact">
					<div className="content">
						<h3 style={{ marginBottom: '2rem' }}>
							<b>{translations.contact.email[selectedLanguage]}</b>
						</h3>
						<h3 style={{ marginBottom: '2rem' }}>gspr@gspr.is</h3>
						<hr />
						<h3 style={{ marginBottom: '2rem' }}>
							<b>{translations.contact.phone[selectedLanguage]}</b>
						</h3>
						<h3 style={{ marginBottom: '2rem' }}>557-1515</h3>
						<hr />
						<h3 style={{ marginBottom: '2rem' }}>
							<b>{translations.contact.address[selectedLanguage]}</b>
						</h3>
						<h3 style={{ marginBottom: '2rem' }}>Súðarvogur 38, 104 Reykjavík</h3>
						<hr />
					</div>
					<div style={{ margin: '0 auto' }}>
						<iframe
							title="Map"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1372.2232563479186!2d-21.848708518614202!3d64.12939711090274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d6749c7f8b1333%3A0xdf3a4b9053374a3!2zU8O6w7BhcnZvZ3VyIDM4LCBSZXlramF2w61rLCBJY2VsYW5k!5e0!3m2!1sen!2sus!4v1642559018128!5m2!1sen!2sus"
							width={'100%'}
							height={600}
							style={{ border: 'none' }}
							allowFullScreen={false}
						></iframe>
					</div>
				</Container>
			</>
		)}
	</LanguageContext.Consumer>
);
