import React from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent: any, options = {}) {
  const HOC: React.FC = (props: { [value: string]: any }) => {
    const trackPage = (page: string) => {
      ReactGA.pageview(page);
    };

    React.useEffect(() => {
      const page = props.location.pathname + props.location.search;
      trackPage(page);
      // eslint-disable-next-line
    }, []);

    return <WrappedComponent {...props} />;
  };

  return HOC;
}
